import Vue from "vue";
import Vuex from "vuex";

import router from "@/router";

import {
	Message
} from "element-ui";


import createPersistedState from "vuex-persistedstate";


import ajax from "@/utils/request.js"; //导入 axios 配置
const api = ajax.api; //请求方法

// debugger
console.log("========== ajax ==========", ajax);

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		router_list: [], //顶部路由
		list_const: {}, //系统配置
		 

		vuex_user: {}, //用户信息
		vuex_unread_number: 0, //未读数量
		vuex_type_list:[],
	},

	getters: {},

	mutations: {
		set_vuex_user(state, data) {
			localStorage.setItem('cache_user', JSON.stringify(data))
			state.vuex_user = data
		},
		clear_vuex_user(state, data) {
			state.vuex_user = {}
			localStorage.removeItem('token')
		},
		set_vuex_unread_number(state, data) {
			state.vuex_unread_number = data
		},
		//

		routers(state, val) {
			// console.log('vuexxx',state,1,val)
			state.router_list = val
		},
		list_const(state, obj) {
			// console.log(obj)
			state[obj.title] = obj.arr
			// console.log(state)
		},
		
	},
	actions: {
		//初始化查询
		appInit({
			commit,
			dispatch,
			state
		}, data) {
			let token = localStorage.getItem('token')
			if (!token) {
				return
			}
			dispatch('query_user')
		},
		//查询用户信息
		query_user({
			commit,
			dispatch,
			state
		}, data) {
			api('getAdminInfo', {}, "get").then((res) => {
				if (res.code == 200) {
					commit('set_vuex_user', res.data)
					if (res.data.role_id) {
						dispatch('query_role', res.data)
						dispatch('query_notice')
					}
				}
			})
		},
		// 查询用户权限
		query_role({
			commit,
			dispatch,
			state
		}, data) {
			api('getAdminRole', {
				id: data.role_id
			}, "get").then((res) => {
				if (res.code == 200) {
					if (res.data.roles) {
						//控制菜单权限
					}
				}
			})
		},

		//查询消息通知
		query_notice({
			commit,
			dispatch,
			state
		}, data) {
			api("getAdminLogList", {
				is_read: 1, //0全部 1未读 2 已读
				page: 1,
				limit: 10,
			}, "get").then((res) => {
				if (res.code == 200) {
					commit('set_vuex_unread_number', res.data.count)
				}
			});
		},
	 
		//选项配置
		get_type_setting({
			commit,
			state
		}, data) {
			api('getTypeList', {}, "get").then((res) => {
				//  console.log('系统设置', res);
				if (res.code == 200) {
					let list = res.data;
					list.forEach((v) => {
						v.inputVisible = false;
						v.inputValue = "";
						v.ref = "saveTagInput" + v.id;
					});

					let v1_list = list.filter(v => v.parent_id == 0)
					let v2_list = list.filter(v => v.parent_id != 0)
					v1_list.forEach(v1 => {
						let child = v2_list.filter(v2 => v2.parent_id == v1.id) || []
						v1.child = child
					})
					// this.dataList = v1_list;
					console.log('v1_list',v1_list)
					let obj={
						title:'vuex_type_list',
						arr:v1_list
					}
					commit('list_const', obj)
				}

			})
		},
		 
		 


	},
	modules: {
		// 导出 打印等操作的封装

	},

	plugins: [
		createPersistedState({
			// 存储方式：localStorage、sessionStorage、cookies
			storage: window.localStorage,
			// 存储的 key 的key值
			key: "store",
			render(state) {
				// 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
				return {
					...state
				};
			}
		})
	]
});