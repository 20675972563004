import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VeLine from "v-charts/lib/line.common";
import VeHistogram from "v-charts/lib/histogram.common";
import VePie from "v-charts/lib/pie.common";
// import VeMap from "v-charts/lib/map.common";

[VeLine, VeHistogram, VePie].forEach((comp) => {
	Vue.component(comp.name, comp);
});

import up_files from './components/upload/import_file.vue'
Vue.component('up_files', up_files)

import bumen_cascader from '@/components/bumen/bumen_cascader.vue';//部门选择
Vue.component('bumen_cascader', bumen_cascader)

import upload_tip from '@/components/upload/upload_tip.vue';//上传提示
Vue.component('upload_tip', upload_tip)




import echarts from 'echarts'
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts

import "@/assets/css/reset.css";
import "@/utils/request.js"; //  axios 配置
import "@/utils/form-validate.js"; //  表单验证配置

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import {
	Message
} from "element-ui";
Vue.prototype.$message = Message;

Vue.use(ElementUI);


// 加载
import {
	Loading
} from "element-ui";
Vue.prototype.$load = {
	loading: null,
	open: function() {
		this.loading = Loading.service({
			fullscreen: true,
		});
	},
	close: function() {
		if (!this.loading) return false;
		this.loading.close();
	},
};

// 混入
import _mixin from "./mixin";
Vue.mixin(_mixin);

// 提示
window.alert = function(res) {
	console.log(res)
	let {
		msg,
		code
	} = res;
	if (code == 200) {
		Message.success({
			message: msg,
			// offset: 300,
		});
	} else {
		Message.error({
			message: msg,
			// offset: 300,
		});
	}
};
window.alertSucc = function(msg) {
	Message.success({
		message: msg,
		// offset: 300,
	});
};
window.alertErr = function(msg) {
	Message.error({
		message: msg,
		// offset: 300,
	});
};
window.alertInfo = function(msg) {
	Message.info({
		message: msg,
		// offset: 300,
	});
};

store.dispatch("appInit"); //重新初始化

Vue.config.productionTip = false;

new Vue({
	router,
	store,

	render: (h) => h(App),
}).$mount("#app");