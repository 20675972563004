import Vue from "vue";
import store from "../store/index";
import VueRouter from "vue-router";

import {
	MessageBox
} from "element-ui";

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
	return originalReplace.call(this, location).catch((err) => err);
};
// 登录
const login = () => import("@/views/login.vue");
const forgot_password = () => import("@/views/forgot_password.vue");

// 菜单页
const Index = () => import("@/views/index.vue");


//员工管理  Employee_management
//员工信息
const Employee_Information = () => import("@/views/Employee_management/Employee_Information.vue");
//员工信息登记
const Employee_Information_add = () => import("@/views/Employee_management/Employee_Information_add.vue");
//登记成功
const Employee_Information_add_success = () => import(
	"@/views/Employee_management/Employee_Information_add_success.vue");
// 员工详情
const Employee_Information_detial = () => import("@/views/Employee_management/Employee_Information_detial.vue");
//员工详情的子组件
//员工基础信息
const employee_info = () => import("@/components/Employee_Information_detial/employee_info.vue");
//通讯录信息
const tongxunlu_info = () => import("@/components/Employee_Information_detial/tongxunlu_info");
//合同记录
const employee_ht = () => import("@/components/Employee_Information_detial/employee_ht.vue");
//调动记录
const employee_dd = () => import("@/components/Employee_Information_detial/employee_dd.vue");
//福利记录
const employee_fl = () => import("@/components/Employee_Information_detial/employee_fl.vue");
//劳保记录
const employee_lb = () => import("@/components/Employee_Information_detial/employee_lb.vue");
//工资配置
const employee_gz = () => import("@/components/Employee_Information_detial/employee_gz.vue");
//工资记录
const employee_gzjl = () => import("@/components/Employee_Information_detial/employee_gzjl.vue");
// 考勤记录
const employee_kq = () => import("@/components/Employee_Information_detial/employee_kq.vue");
//离职记录
const employee_lz = () => import("@/components/Employee_Information_detial/employee_lz.vue");
//社保配置
const employee_sb = () => import("@/components/Employee_Information_detial/employee_sb.vue");


//添加简历
const Employee_Information_resume = () => import("@/views/Employee_management/Employee_Information_resume.vue");
//员工信息离职
const Employee_Information_dimission = () => import("@/views/Employee_management/Employee_Information_dimission.vue");



//通讯管理
const Newsletter_management = () => import("@/views/Employee_management/Newsletter_management.vue");
//通讯管理 添加修改成功
const Newsletter_management_success = () => import("@/views/Employee_management/Newsletter_management_success.vue");
//合同管理
const Contract_management = () => import("@/views/Employee_management/Contract_management.vue");
//合同管理 人员的
const Contract_management_user = () => import("@/views/Employee_management/Contract_management_user.vue");
//员工调动
const Employee_transfers = () => import("@/views/Employee_management/Employee_transfers.vue");
//员工调动 个人
const Employee_transfers_user = () => import("@/views/Employee_management/Employee_transfers_user.vue");


//组织架构
const Organizational_structure = () => import("@/views/Employee_management/Organizational_structure.vue");
//岗位管理
const  post_management= () => import("@/views/Employee_management/post_management.vue");
//历史记录
const History_recording = () => import("@/views/Employee_management/History_recording.vue");

//薪酬管理  Payroll_management
//社保管理
const Social_security_management = () => import("@/views/Payroll_management/Social_security_management.vue");



//社保管理 - 社保减员历史
const shebao_delete_history = () => import("@/views/Payroll_management/shebao_delete_history.vue");
//员工薪资
const Employee_salary = () => import("@/views/Payroll_management/Employee_salary.vue");






//员工薪资修改
const Employee_salary_modifications = () => import("@/views/Payroll_management/Employee_salary_modifications.vue");




//员工薪资 - 岗位薪资设置
const position_setting = () => import("@/views/Payroll_management/position_setting.vue");
//结算月数据
const Billing_month_data = () => import("@/views/Payroll_management/Billing_month_data.vue");

// 薪资设置
const Salary_Management = () => import("@/views/Payroll_management/Salary_Management.vue");
// 员工标准工资设置
const Salary_setting = () => import("@/views/Payroll_management/Salary_setting.vue");
// 工资下发
const Salary_distribution = () => import("@/views/Payroll_management/Salary_distribution");
// 工资下发新增
const Salary_distribution_add = () => import("@/views/Payroll_management/Salary_distribution_add");


//考勤管理 Attendance_management

// 废弃
//每日统计
// const Daily_statistics = () => import("@/views/Attendance_management/Daily_statistics.vue");
//月度汇总
// const Monthly_rollups = () => import("@/views/Attendance_management/Monthly_rollups.vue");
//历史查询
const Historical_queries = () => import("@/views/Attendance_management/Historical_queries.vue");
// 考勤人员管理
const kaoqin_user_manager = () => import("@/views/Attendance_management/kaoqin_user_manager.vue");


//考勤设置   Attendance_settings
//考勤规则
const Attendance_rules = () => import("@/views/Attendance_management/Attendance_settings/Attendance_rules.vue");
const Attendance_rules_set = () => import("@/views/Attendance_management/Attendance_settings/Attendance_rules_set.vue");

//免考勤人员设置
const Attendance_no = () => import("@/views/Attendance_management/Attendance_settings/Attendance_no.vue");

//下井设置
const Downhole_settings = () => import("@/views/Attendance_management/Attendance_settings/Downhole_settings.vue");

//节假日设置
const Holiday_settings = () => import("@/views/Attendance_management/Attendance_settings/Holiday_settings.vue");



//考勤数据   Attendance_data
//每日统计

const Daily_statistics = () => import("@/views/Attendance_management/Attendance_data/Daily_statistics.vue");
//月度汇总
const Monthly_rollups = () => import("@/views/Attendance_management/Attendance_data/Monthly_rollups.vue");

// 打卡明细
const Open_Details = () => import("@/views/Attendance_management/Attendance_data/Open_Details.vue");
// 考勤明细
const Record_sheet = () => import("@/views/Attendance_management/Attendance_data/Record_sheet.vue");
// 部门考勤
const Departmental_Attendance = () => import(
	"@/views/Attendance_management/Attendance_data/Departmental_Attendance.vue");



//员工福利 Employee_benefits
//礼品管理
const Gift_management = () => import("@/views/Employee_benefits/Gift_management.vue");
//员工福利
const Employee_benefits = () => import("@/views/Employee_benefits/Employee_benefits.vue");
// 礼品下发
const Gift_distributed = () => import("@/views/Employee_benefits/Gift_distributed.vue");
// 礼品下发新增
const Gift_distributed_add = () => import("@/views/Employee_benefits/Gift_distributed_add.vue");

//劳保用品 Labor_protection_supplies

//劳保用品管理
const Labor_Management = () => import("@/views/Labor_protection_supplies/Labor_Management.vue");
// 领取劳保用品
const Labor_Receive = () => import("@/views/Labor_protection_supplies/Labor_Receive.vue");
//领取次数的数据
const Labor_Receive_lq = () => import("@/views/Labor_protection_supplies/Labor_Receive_lq.vue");
const Labor_Receive_bm= () => import("@/views/Labor_protection_supplies/Labor_Receive_bm.vue");
//劳保用品下发
const Labor_distributed = () => import("@/views/Labor_protection_supplies/Labor_distributed.vue");
//劳保用品下发新增
const Labor_distributed_add = () => import("@/views/Labor_protection_supplies/Labor_distributed_add.vue");


// 记录
const Labor_History = () => import("@/views/Labor_protection_supplies/Labor_History.vue");


//统计报表 Statistical_reports
//实时报表 
const Real_time_reports = () => import("@/views/Statistical_reports/Real_time_reports.vue");
//月报预览 
const monthly_report = () => import("@/views/Statistical_reports/monthly_report.vue");
//历史月报 
const Historical_Monthly = () => import("@/views/Statistical_reports/Historical_Monthly.vue");

//账号管理 Account_management
//账号管理 
const Account_management = () => import("@/views/Account_management/Account_management.vue");
//角色管理 
const Role_management = () => import("@/views/Account_management/Role_management.vue");
//系统设置
const system_setting = () => import("@/views/Account_management/system_setting.vue");


// 修改密码
const changePassword = () => import("@/views/Account_management/changePassword.vue");
// 消息通知
const messages = () => import("@/views/Account_management/messages.vue");
//编辑用户信息
const edit_info = () => import("@/views/Account_management/edit_info.vue");



const routes = [{
		path: "/",
		name: "login",
		component: login,
		meta: {
			title: "登录",
		},
	},
	{
		path: "/forgot_password",
		name: "forgot_password",
		component: forgot_password,
		meta: {
			title: "忘记密码",
		},
	},


	{
		path: "/index",
		name: "index",
		component: Index,
		redirect: "/Employee_Information",
		meta: {
			title: "列表  ",
			keepAlive: false,
		},
		children: [
			// 员工管理

			{
				path: "/Employee_Information",
				name: "Employee_Information",
				component: Employee_Information,
				meta: {
					title: "员工信息",
				},
			},
			{
				path: "/Employee_Information_add",
				name: "Employee_Information_add",
				component: Employee_Information_add,
				meta: {
					title: "入职登记",
				},
			},
			{
				path: "/Employee_Information_add_success",
				name: "Employee_Information_add_success",
				component: Employee_Information_add_success,
				meta: {
					title: "入职登记",
				},
			},
			{
				path: "/Employee_Information_detial",
				name: "Employee_Information_detial",
				component: Employee_Information_detial,
				redirect: "/Employee_Information_detial/employee_info",
				meta: {
					title: "入职登记",
				},
				children:[
					{
						path: "/Employee_Information_detial/employee_info",
						name: "Employee_Information_detial/employee_info",
						component: employee_info,
						meta: {
							title: "员工基础信息",
						},
					},{
						path: "/Employee_Information_detial/tongxunlu_info",
						name: "Employee_Information_detial/tongxunlu_info",
						component: tongxunlu_info,
						meta: {
							title: "通讯录信息",
						},
					},

					{
						path: "/Employee_Information_detial/employee_ht",
						name: "Employee_Information_detial/employee_ht",
						component: employee_ht,
						meta: {
							title: "合同记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_dd",
						name: "Employee_Information_detial/employee_dd",
						component: employee_dd,
						meta: {
							title: "调动记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_fl",
						name: "Employee_Information_detial/employee_fl",
						component: employee_fl,
						meta: {
							title: "福利记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_lb",
						name: "Employee_Information_detial/employee_lb",
						component: employee_lb,
						meta: {
							title: "劳保记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_gz",
						name: "Employee_Information_detial/employee_gz",
						component: employee_gz,
						meta: {
							title: "工资配置",
						},
					},
					{
						path: "/Employee_Information_detial/employee_gzjl",
						name: "Employee_Information_detial/employee_gzjl",
						component: employee_gzjl,
						meta: {
							title: "工资记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_kq",
						name: "Employee_Information_detial/employee_kq",
						component: employee_kq,
						meta: {
							title: "考勤记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_lz",
						name: "Employee_Information_detial/employee_lz",
						component: employee_lz,
						meta: {
							title: "离职记录",
						},
					},
					{
						path: "/Employee_Information_detial/employee_sb",
						name: "Employee_Information_detial/employee_sb",
						component: employee_sb,
						meta: {
							title: "社保配置",
						},
					},
					
					
				]
			},
			{
				path: "/Employee_Information_resume",
				name: "Employee_Information_resume",
				component: Employee_Information_resume,
				meta: {
					title: "添加简历",
				},
			},
			{
				path: "/Employee_Information_dimission",
				name: "Employee_Information_dimission",
				component: Employee_Information_dimission,
				meta: {
					title: "员工信息", //离职
				},
			},



			{
				path: "/Newsletter_management",
				name: "Newsletter_management",
				component: Newsletter_management,
				meta: {
					title: "通讯管理",
				},
			},
			{
				path: "/Newsletter_management_success",
				name: "Newsletter_management_success",
				component: Newsletter_management_success,
				meta: {
					title: "通讯信息",
				},
			},


			{
				path: "/Contract_management",
				name: "Contract_management",
				component: Contract_management,
				meta: {
					title: "合同管理",
				},
			},
			{
				path: "/Contract_management_user",
				name: "Contract_management_user",
				component: Contract_management_user,
				meta: {
					title: "合同管理",
				},
			},

			{
				path: "/Employee_transfers",
				name: "Employee_transfers",
				component: Employee_transfers,
				meta: {
					title: "员工调动",
				},
			},
			{
				path: "/Employee_transfers_user",
				name: "Employee_transfers_user",
				component: Employee_transfers_user,
				meta: {
					title: "员工调动",
				},
			},

			
			{
				path: "/Organizational_structure",
				name: "Organizational_structure",
				component: Organizational_structure,
				meta: {
					title: "组织架构",
				},
			},

			{
				path: "/post_management",
				name: "post_management",
				component: post_management,
				meta: {
					title: "岗位管理",
				},
			},

			
			{
				path: "/History_recording",
				name: "History_recording",
				component: History_recording,
				meta: {
					title: "历史记录",
				},
			},

			//薪酬管理
			{
				path: "/Social_security_management",
				name: "Social_security_management",
				component: Social_security_management,
				meta: {
					title: "社保管理",
				},
			},
			{
				path: "/shebao_delete_history",
				name: "shebao_delete_history",
				component: shebao_delete_history,
				meta: {
					title: "历史减员记录",
				},
			},
			{
				path: "/Employee_salary",
				name: "Employee_salary",
				component: Employee_salary,
				meta: {
					title: "员工薪资",
				},
			},

			{
				path: "/Employee_salary_modifications",
				name: "Employee_salary_modifications",
				component: Employee_salary_modifications,
				meta: {
					title: "员工薪资修改",
				},
			},


			
			{
				path: "/position_setting",
				name: "position_setting",
				component: position_setting,
				meta: {
					title: "岗位薪资设置",
				},
			},
			{
				path: "/Billing_month_data",
				name: "Billing_month_data",
				component: Billing_month_data,
				meta: {
					title: "结算月数据",
				},
			},
			// 新增
			{
				path: "/Salary_Management",
				name: "Salary_Management",
				component: Salary_Management,
				meta: {
					title: "薪资设置",
				},
			},
			{
				path: "/Salary_setting",
				name: "Salary_setting",
				component: Salary_setting,
				meta: {
					title: "员工标准工资设置",
				},
			},
			
			{
				path: "/Salary_distribution",
				name: "Salary_distribution",
				component: Salary_distribution,
				meta: {
					title: "工资下发",
				},
			},
			{
				path: "/Salary_distribution_add",
				name: "Salary_distribution_add",
				component: Salary_distribution_add,
				meta: {
					title: "新增工资",
				},
			},
			
			
			
			
			//考勤管理
			//考勤设置 Attendance_settings
			{
				path: "/Attendance_settings/Attendance_rules",
				name: "Attendance_settings/Attendance_rules",
				component: Attendance_rules,
				meta: {
					title: "考勤规则",
				},
			},
			{
				path: "/Attendance_settings/Attendance_rules_set",
				name: "Attendance_settings/Attendance_rules_set",
				component: Attendance_rules_set,
				meta: {
					title: "考勤规则设置",
				},
			},
			{
				path: "/Attendance_settings/Attendance_no",
				name: "Attendance_settings/Attendance_no",
				component: Attendance_no,
				meta: {
					title: "免考勤人员设置",
				},
			},
			{
				path: "/Attendance_settings/Downhole_settings",
				name: "Attendance_settings/Downhole_settings",
				component: Downhole_settings,
				meta: {
					title: "下井设置",
				},
			},
			{
				path: "/Attendance_settings/Holiday_settings",
				name: "Attendance_settings/Holiday_settings",
				component: Holiday_settings,
				meta: {
					title: "节假日设置",
				},
			},






			//考勤数据
			{
				path: "/Attendance_data/Daily_statistics",
				name: "Attendance_data/Daily_statistics",
				component: Daily_statistics,
				meta: {
					title: "每日统计",
				},
			},
			{
				path: "/Attendance_data/Monthly_rollups",
				name: "Attendance_data/Monthly_rollups",
				component: Monthly_rollups,
				meta: {
					title: "月度汇总",
				},
			},

			// 新增
			{
				path: "/Attendance_data/Record_sheet",
				name: "Attendance_data/Record_sheet",
				component: Record_sheet,
				meta: {
					title: "考勤明细",
				},
			},
			{
				path: "/Attendance_data/Open_Details",
				name: "Attendance_data/Open_Details",
				component: Open_Details,
				meta: {
					title: "打卡明细",
				},
			},
			{
				path: "/Attendance_data/Departmental_Attendance",
				name: "Attendance_data/Departmental_Attendance",
				component: Departmental_Attendance,
				meta: {
					title: "部门考勤",
				},
			},



			{
				path: "/Historical_queries",
				name: "Historical_queries",
				component: Historical_queries,
				meta: {
					title: "历史查询",
				},
			},
			{
				path: "/kaoqin_user_manager",
				name: "kaoqin_user_manager",
				component: kaoqin_user_manager,
				meta: {
					title: "考勤人员管理",
				},
			},


			//员工福利
			{
				path: "/Gift_management",
				name: "Gift_management",
				component: Gift_management,
				meta: {
					title: "礼品管理",
				},
			},
			{
				path: "/Employee_benefits",
				name: "Employee_benefits",
				component: Employee_benefits,
				meta: {
					title: "员工福利",
				},
			},
			{
				path: "/Gift_distributed",
				name: "Gift_distributed",
				component: Gift_distributed,
				meta: {
					title: "礼品下发",
				},
			},

			{
				path: "/Gift_distributed_add",
				name: "Gift_distributed_add",
				component: Gift_distributed_add,
				meta: {
					title: "新增礼品下发",
				},
			},


			

			//劳保用品
			{
				path: "/Labor_Management",
				name: "Labor_Management",
				component: Labor_Management,
				meta: {
					title: "劳保用品管理",
				},
			},
			{
				path: "/Labor_Receive",
				name: "Labor_Receive",
				component: Labor_Receive,
				meta: {
					title: "领取劳保用品",
				},
			},
			{
				path: "/Labor_Receive_lq",
				name: "Labor_Receive_lq",
				component: Labor_Receive_lq,
				meta: {
					title: "劳保用品发放记录",
				},
			},
			{
				path: "/Labor_Receive_bm",
				name: "Labor_Receive_bm",
				component: Labor_Receive_bm,
				meta: {
					title: "劳保用品发放记录",
				},
			},
			
			
			{
				path: "/Labor_History",
				name: "Labor_History",
				component: Labor_History,
				meta: {
					title: "劳保用品管理",
				},
			},

			{
				path: "/Labor_distributed",
				name: "Labor_distributed",
				component: Labor_distributed,
				meta: {
					title: "劳保用品下发",
				},
			},
			{
				path: "/Labor_distributed_add",
				name: "Labor_distributed_add",
				component: Labor_distributed_add,
				meta: {
					title: "新增劳保用品",
				},
			},

			


			//统计报表
			{
				path: "/Real_time_reports",
				name: "Real_time_reports",
				component: Real_time_reports,
				meta: {
					title: "实时报表",
				},
			},
			{
				path: "/monthly_report",
				name: "monthly_report",
				component: monthly_report,
				meta: {
					title: "月报预览",
				},
			},
			{
				path: "/Historical_Monthly",
				name: "Historical_Monthly",
				component: Historical_Monthly,
				meta: {
					title: "历史月报",
				},
			},

			//账号管理
			{
				path: "/Account_management",
				name: "Account_management",
				component: Account_management,
				meta: {
					title: "账号管理",
				},
			},
			{
				path: "/Role_management",
				name: "Role_management",
				component: Role_management,
				meta: {
					title: "角色管理",
				},
			},
			{
				path: "/system_setting",
				name: "system_setting",
				component: system_setting,
				meta: {
					title: "选项设置",
				},
			},
			{
				path: "/changePassword",
				name: "changePassword",
				component: changePassword,
				meta: {
					title: "修改密码",
				},
			},
			{
				path: "/messages",
				name: "messages",
				component: messages,
				meta: {
					title: "消息通知",
				},
			},
			
			{
				path: "/edit_info",
				name: "edit_info",
				component: edit_info,
				meta: {
					title: "修改信息",
				},
			},
			
		]
	},
	{
		path: "*",
		redirect: "/",
	},
];

const scrollBehavior = (to, from, savedPosition) => {
	// console.log('滚动行为', to, from, savedPosition)
	if (savedPosition) {
		return savedPosition;
	} else {
		return {
			x: 0,
			y: 0
		};
	}
};
const router = new VueRouter({
	routes,
	base: "/",
	// mode: "hash",
	mode: "history",
	scrollBehavior,
});

router.beforeEach((to, from, next) => {
	// debugger
	// console.log("路由导航守卫 to", to);
	// console.log("路由导航守卫 from", from);
	if (to.meta.title) {
		/* 路由发生变化修改页面title */
		// document.title = to.meta.title;
	}

	// debugger

	// let token = localStorage.getItem("token");
	console.log('to', to)
	let token = localStorage.getItem("token") || ''
	if (to.fullPath == '/' || to.fullPath == '/forgot_password') {
		next()
	} else {
		if (token) {
			next()
			return
		} else {
			next('/')
			// next({ ...to, replace: true })
			return
		}
	}


	// 兼容IE

	// next(); 
	// debugger
	// if (to.name == "login") {
	//   next();

	// } else if (token) {
	//   next()
	// }else{
	//   next('/')
	// }
});

// router.beforeResolve((to, from, next) => {
//   console.log("beforeResolve 路由守卫", to);
//   console.log("beforeResolve 路由守卫", from);

//   if (from.query && from.query.role) {
//     if (!to.query || !to.query.role) {
//       to.query.role = from.query.role;
//     }
//   }

//   next()
// });

router.afterEach((to, from) => {
	// console.log("************** afterEach **************", to, from);
	// let is_need_user_full_info = false;
	// let need_user_full_info_routes = ["formsCompanyView", "formsCompanyInfo", "formsCompanyEmployee", "formsQuarterlyList", "formsQuarterlyInfo", "formsAnnualList", "formsAnnualCompanyQualification", "formsAnnualFinancial", "formsAnnualIntellectualProperty", "formsAnnualProjectList", "formsAnnualProjectApproval", "formsAnnualProjectPayment", "formsAnnualProjectResearch", "serviceCar", "serviceClear", "serviceRepair", "serviceMeetingRoom"];
	// // debugger
	// if (need_user_full_info_routes.includes(to.name)) {
	//   is_need_user_full_info = true;
	// }
	// // debugger
	// //是否需要用户补全资料后填写  当前用户是否已经补全资料
	// let baseInfo = {};
	// if (localStorage.getItem("baseInfo")) {
	//   baseInfo = JSON.parse(localStorage.getItem("baseInfo")) || {};
	// }
	// //用户资料审核通过 info_state == 3
	// // && baseInfo.info_state != 3
	// if (is_need_user_full_info) {
	//   if (localStorage.getItem("token")) {
	//     store.dispatch("router_getUserInfo");
	//   }
	//   // console.log("需要用户补全资料 并且用户未补全资料");
	//   // store.commit("pop_info_tip_time");
	//   // next();
	// } else {
	//   // next();
	// }


});

export default router;