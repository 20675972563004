<template>
	<div id="app">
		<div class="main" :class="$route.name">
			<!-- <div v-bind:style="styleObject" class="scale-box"> -->
			<router-view />
		</div>
	</div>
</template>

<script>
	// import debounce from "lodash.debounce"
	import DevicePixelRatio from './utils/devicePixelRatio';

	export default {
		components: {},
		props: {
			width: {
				type: Number,
				default: 1920,
			},
			height: {
				type: Number,
				default: 1080,
			},
		},
		data() {
			return {
				scale: this.getScale(),

			};
		},
		computed: {
			styleObject() {
				let obj = {
					transform: `scale(${this.scale}) translate(-50%, -50%)`,
					WebkitTransform: `scale(${this.scale}) translate(-50%, -50%)`,
					width: this.width + "px",
					height: this.height + "px",
				}
				return obj
			}

		},
		watch: {
			$route: {
				handler(newVal, oldVal) {
					// console.log( this.$route.matched)
				},
				immediate: true,
				deep: true
			},
		},
		created() {
			// this.list_zhan()
			// new DevicePixelRatio().init();
		},
		mounted() {
			this.initScale();
			this.getScale()
			window.addEventListener("resize", this.setScale)
		},
		methods: {
			initScale() {
				// console.log(" ************ 屏幕宽度 ************ ", document.documentElement.clientWidth);
				if (document.documentElement.clientWidth <= 768) {
					this.is_mobile = true;
				}

				// if (document && document.documentElement && document.documentElement.clientWidth) {
				//   if (document.documentElement.clientWidth < 1280) {
				//     document.querySelector("body").style.overflowX = "auto";

				//     var $target = document.querySelector('[name="viewport"]');
				//     console.log(" ********** 设置视口 ********** ", $target);
				//     document.querySelector('[name="viewport"]').setAttribute("content", "width=device-width,  initial-scale=0.15");
				//   }
				// }
			},
			getScale() {
				// 固定好16:9的宽高比，计算出最合适的缩放比，宽高比可根据需要自行更改
				// console.log(window.innerWidth,'window.innerWidth');
				let ww = window.innerWidth / this.width
				let wh = window.innerHeight / this.height
				return ww < wh ? ww : wh
			},
			// setScale: debounce(function() {

			// 	// 获取到缩放比，设置它
			// 	let scale = this.getScale()
			// 	this.scale = scale
			// 	// console.log(this.scale, "---")
			// }, 500),
		},
		beforeDestroy() {
			// window.addEventListener("resize", this.setScale)
		},
	};
</script>

<style lang="less">
	.flex {
		display: flex;
		align-items: center;
	}
	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}














	* {
		box-sizing: border-box;
		list-style: none;
		padding: 0;
		margin: 0;
		font-family: Microsoft YaHei;
		box-sizing: border-box;
		transition: 0.1s;
	}

	html {
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: hidden;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	.scale-box {
		transform-origin: 0 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transition: 0.3s;
	}

	a {
		text-decoration: none;
	}

	.img-box {
		overflow: hidden;
	}

	img {
		object-fit: cover;
	}

	.hover {
		a:hover {
			opacity: 0.8;
		}
	}

	button {
		cursor: pointer;
	}

	input,
	textarea {
		outline: none;
		// -webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
		border: none;
		background: transparent;
		resize: none;
	}

	button,
	input,
	textarea {
		color: inherit;
		font: inherit;
		outline: none;
		border: none;

		text-indent: 0 !important;
	}

	button {
		transition: 0.3s;

		&:hover {
			opacity: 0.75;
		}
	}

	a {
		transition: 0.3s;

		&:hover {
			opacity: 0.9;
		}
	}

	img {
		vertical-align: middle;
		max-width: 100%;
	}

	.index_user_btn_list {
		width: 200px !important;

		.index_user_btns {
			display: flex;

			.index_user_btns_img {}

			.index_user_btns_text {
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #273A8E;
				padding-left: 10px;
			}
		}
	}

	.el_table_title {
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		font-size: 14px;
		color: #333333;
		background: #EAECEE !important;
		// text-align: center;
	}

	.btn_img {
		cursor: pointer;
		width: 30px;
		text-align: center;

		img {
			width: 5px;
			height: 20px;
		}

	}

	.scope_ht_box {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: center;

		.imgs {
			img {
				width: 14px;
				height: 14px;
				margin-right: 10px;
			}
		}

		.text {
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 14px;
			color: #FF5E00;
		}
	}

	.daoqi_time {
		display: flex;
		align-items: center;
		justify-content: center;

		.time_text {
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 14px;
			color: #4A4A4A;
			margin-right: 9px;
		}

		.daoqi_texts_colo1 {
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 12px;
			color: #FF7440;
		}

		.daoqi_texts_colo2 {
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 12px;
			color: #FF2300;
		}
	}
	
	
	
	
	
	
	
	
	
	//  表格样式处理
	// 单元格居中
	.el-table .el-table__cell {
		text-align: center !important;
	}
	// 表头单元格边框
	.el-table--border th.el-table__cell, .el-table__fixed-right-patch {
		border-bottom: 1px solid #DBDBDB !important;
	}
	.el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: 1px solid #DBDBDB !important;
	}
	.btn_text_dr_sb{
		width: 150px;
		text-align: center;
	}
</style>