export default {
  /**
   * 所有接口
   * successActionTip: 是否需要展示接口调用成功提示
   * successActionTip: 是否需要展示接口调用失败提示
   * api: 接口名
   * title: 接口标题
   */
  ApiList: [
    { successActionTip: 1, failActionTip: 1, api: "login", title: "登录" },
     
  ],
};
