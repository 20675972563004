import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Message } from "element-ui";

// import needAuthApiNames from "@/store/needAuthApiNames.js";
import ApiList from "@/utils/ApiList.js";
// let needAuthApi = needAuthApiNames.needAuthApiList.map((item) => item.api); //需要用户登录授权的接口

let ApiList_successActionTip = ApiList.ApiList.filter((v) => v.successActionTip).map((v) => v.api); //接口操作成功后需要展示提示的接口
let ApiList_failActionTip = ApiList.ApiList.filter((v) => v.failActionTip).map((v) => v.api); //接口操作失败后需要展示提示的接口
// console.log("所有需要授权的接口 needAuthApi", needAuthApi);
// console.log("接口操作成功后需要展示提示的接口 ApiList_successActionTip", ApiList_successActionTip);
// console.log("接口操作失败后需要展示提示的接口 ApiList_failActionTip", ApiList_failActionTip);

// axios.defaults.baseURL = '/api';
//设置axios为form-data
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.transformRequest = [
  function (data) {
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    return ret;
  },
];

let urls = process.env.VUE_APP_API_ROOT

// 添加请求拦截器
// 在发送请求之前做些什么("请求拦截器")
axios.interceptors.request.use(
  (config) => {
    //添加过期监控
    // localStorage.setItem("lastest_action_time", new Date().getTime());
    // console.log(" *************** request.js 添加过期监控 *************** ");
    // // debugger;

    // let baseInfo = {};
    // if (localStorage.getItem("baseInfo")) {
    //   baseInfo = JSON.parse(localStorage.getItem("baseInfo"));
    // }

    // //接口不需要 user_id  专家请求项目列表时
    // if (config.params && config.params.no_user_id) {
    //   console.log("当前用户是导师专家身份");
    //   config.params.user_id = "";
    // }

    // if (config.data) {
    //   for (var key in config.data) {
    //     if (config.data[key] === "") {
    //       delete config.data[key];
    //     }
    //   }
    // }

    // debugger
    
    // let token=localStorage.getItem("token")
    // let url=config.url.split('/')
    // console.log(url)
    //   if(!token&&url[url.length-1]!="login" ){
    //     alertErr('请登录')
    //     return 
    //   }
    
  
    
    // console.log('axios ====== config', config)

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
//respone拦截器==>对响应做处理
axios.interceptors.response.use(
  function (res) {
    // console.log("-------------- axios拦截 success----------------", res);
    let data = res.data;
    let actionName_arr = res.config.url.split("/");

    let actionName = actionName_arr[actionName_arr.length - 1];
    if(data.msg=='请登录'){
      router.push({
              path: "/login"
            });
    }
    if (data && data.code == 0) {
      //操作失败提示
      let is_upload = res.config.data instanceof FormData;
      // let is_upload = false;
      if (is_upload) {
        // actionStr = "";
        // alertErr(res.msg)

      } else {
      }
      if (actionName) {
        if (ApiList_failActionTip.includes(actionName)) {
          // alert(res.data.message);
        }
      }
    } else if (data && data.code == 200) {
      //操作成功提示
      let is_upload = res.config.data instanceof FormData;
      
      if (is_upload) {
      } else {
      }
      if (actionName) {
        if (ApiList_successActionTip.includes(actionName)) {
          
        }
      }
    }

    

    return res.data;
  },
  function (error) {
    Message.error({
      message: '发生错误，请联系管理员',
      // offset: 300,
    });
    console.log("-------------- axios拦截 error----------------");
    return
  }
);

/**
 *
 * @param {*} url
 * @param {*} data
 * @param {*} method
 * @param {*} uploaderConfig   上传文件时需要的的配置信息
 * @returns
 */
function api(url, data, method = "get", uploaderConfig) {
  //  debugger
  let reqUrl = "/api/" + url.trim(); //请求地址
  let reqMethod = method.toLowerCase() || "get"; //请求方式
  let reqData = {
    user_id: localStorage.getItem("user_id") || "",
    // type_id:localStorage.getItem('region')||'',
    token: localStorage.getItem("token") || "", //4535688f9d
    // lang:localStorage.getItem("locale") || "en", //4535688f9d
    ...data,
  };

  if (process.env.NODE_ENV !== "production") {
    // reqUrl = "api/service.php";
    // if(reqUrl.includes('/api2/')) {
    //   reqUrl = reqUrl.replace('/api/', '')
    
    // }
    reqUrl =urls + reqUrl;

  } else {
    reqUrl = urls + reqUrl;
  }

  //debugger
  //axios 配置信息
  //普通请求 使用 x-www 格式
  //  使用 formdata
  let otherConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: uploaderConfig
      ? []
      : [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
            }
            return ret;
          },
        ],
  };

  if (reqMethod == "get") {
    return axios({
      url: reqUrl,
      method: reqMethod,
      params: reqData,
      ...otherConfig,
      ...uploaderConfig,
    });
  } else if (reqMethod == "post") {
    return axios({
      url: reqUrl,
      method: reqMethod,
      data: uploaderConfig ? data : reqData,
      ...otherConfig,
      ...uploaderConfig,
    });
  }
}

function api_up(url, data, method = "get", uploaderConfig) {
  //  debugger
  let reqUrl = "/api/" + url.trim(); //请求地址
  let reqMethod = method.toLowerCase() || "get"; //请求方式
  // let reqData = {
  //   user_id: localStorage.getItem("user_id") || "",
  //   // type_id:localStorage.getItem('region')||'',
  //   token: localStorage.getItem("token") || "c33367701511b4f6020ec61ded352059", //4535688f9d
  //   ...data,
  // };

  if (process.env.NODE_ENV !== "production") {
    // reqUrl = "api/service.php";
    // if(reqUrl.includes('/api2/')) {
    //   reqUrl = reqUrl.replace('/api/', '')
    
    // }
    reqUrl = urls + reqUrl+'?token='+ localStorage.getItem("token");
     

  } else {
    reqUrl = urls + reqUrl+'?token='+ localStorage.getItem("token");
    

  }

  //debugger
  //axios 配置信息
  //普通请求 使用 x-www 格式
  //  使用 formdata
  let otherConfig = {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq"
      // 'Content-Type': 'application/x-www-form-urlencoded',

      "Content-Type": 'multipart/form-data'

    },
    transformRequest: uploaderConfig
      ? []
      : [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
            }
            return ret;
          },
        ],
  };

  if (reqMethod == "get") {
    return axios({
      url: reqUrl,
      method: reqMethod,
      params: data,
      ...otherConfig,
      ...uploaderConfig,
    });
  } else if (reqMethod == "post") {
    return axios({
      url: reqUrl,
      method: reqMethod,
      data:  data,
      ...otherConfig,
      ...uploaderConfig,
    });
  }
}

Vue.prototype.$axios = axios;

Vue.prototype.$api = api;
Vue.prototype.$api_up = api_up;


export default {
  api,
  api_up,
};
