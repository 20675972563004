<template>

	<div class="dialog_box">
		<el-dialog title="导入信息" :visible.sync="shows" @closed="onclosed" :close-on-click-modal="false" :close-on-press-escape="false" >
			<div class="form_box"  >

				<div class="type_box">
					<div class="lefts">
						<div class="icons">1</div>
						<div class="bordes"></div>
					</div>
					<div class="rights">
						<div class="rights_top">
							<div class="text_box">
								<div class="text1">下载模板文件，批量填写信息</div>
								<div class="text2"><span>*</span>建议使用WPS进行表格编辑</div>

							</div>
							<div class="btn_box">
								<div class="btns"><a :href="file_url" download>下载模板</a> </div>
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="lefts">
						<div class="icons">2</div>
						<div class="bordes2"></div>
					</div>
					<div class="rights">
						<div class="rights_top">
							<div class="text_box">
								<div class="text1">选择填好的文件</div>
								<div class="text2"><span>*</span>选择填好的文件</div>
								<div class="text2">
									<span>*</span>仅限上传excel文件
								</div>
							</div>
							<div class="btn_box">
								<div class="btns" @click="handle_up">选择文件</div>
								<form id="uploadForm" v-show="false">
									<input ref="fileRef" type="file" @change="fileChange">
								</form>
							</div>
						</div>
						<div class="rights_bom">
							<div class="up_files" v-if="file_name">
								<span class="span1">{{file_name}}</span>
								<!-- <span class="span2">X</span> -->
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="lefts2">
						<div class="icons">3</div>
					</div>
					<div class="rights">
						<div class="rights_top">
							<div class="text_box">
								<div class="text1">上传文件</div>
							</div>
							<div class="btn_box">
								<div class="btns" @click="onUpload">上传文件</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 引用 -->
		<!--   
        v-show="false" //显示隐藏  
        ref="uploads"//ref名称
        :show_mb="show_mb"//是否判断大小
        :mbs="mbs"//纯数字（单位mb） 
        :show_type="true" //是否判断文件类型
        :types="types"//文件类型 types:JSON.stringify(['jpg','png'])
          -->
		<!-- types:JSON.stringify(['jpg','png']) -->

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {
			// 是否限制大小
			show_mb: {
				type: Boolean,
				default: false
			},
			// 大小
			mbs: {
				type: Number,
				default: 0
			},
			// 是否限制类型
			show_type: {
				type: Boolean,
				default: false
			},
			//类型
			types: {
				type: String,
				default: '[]'
			},
			// 文字提示
			text_up: {
				type: String,
				default: '上传'
			},
		},
		data() {
			return {
				shows: false,
				formData: '', //文件存储
				file_name: '', //文件名称
				file_url: '', //模板文件链接

			};
		},
		methods: {
			onclosed(){
				document.getElementById("uploadForm").reset()
				this.formData=''
				this.file_name=''
				this.file_url=''
			},	

			//打开
			handle_open(obj) {
				this.file_url = obj.file_url

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false

			},
			handle_up() {
				this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
			},
			fileChange(event) {

				// 阻止发生默认行为
				let formData = new FormData()
				let file = this.$refs.fileRef.files[0]
				console.log('上传', file)
				this.name = file.name
				formData.append('file', file)

				if (this.show_mb) {
					let size = Number(file.size)
					size = size / (1024 * 1024)
					if (this.mbs < size) {
						alertErr(`当前限制的文件大小为${this.mbs}mb`)
						document.getElementById("uploadForm").reset()

						return
					}
				}
				if (this.show_type) {
					let type = file.name.split('.')
					// this.types=JSON.parse(this.types)//不能修改父组件传来的值会报错
					let types_in = JSON.parse(this.types)
					console.log('type', type)
					let flag1 = types_in.findIndex(item => item === type[1])
					console.log(flag1)
					if (flag1 == -1) {

						let types = types_in.join(',')
						console.log('types', types)
						alertErr(`当前限制的文件类型${types}格式`)
						document.getElementById("uploadForm").reset()

						return
					}
				}
				this.file_name = file.name
				this.formData = formData

			},
			onUpload() {
				if (!this.formData) {
					alertErr('请选择文件')
					return
				}
				let formData = this.formData
				this.$api_up("upload", formData, "post", {}).then((res) => {
					if (res.code == 200) {
						console.log("文件", res);
						alertSucc('上传成功')
						let datas = {
							name: this.name
						}
						this.shows = false

						this.$emit('handle_up_end', res.data, formData)
						// 上传成功的是时候去除原来的文件
						document.getElementById("uploadForm").reset()
						// this.$refs.fileRef.reset()
					} else {
						document.getElementById("uploadForm").reset()
						// alert(res)
						alertErr(res.msg)
					}

				});
			}
		},
		computed: {},

		watch: {

		},


		created() {


		},


	};
</script>

<style scoped lang="less">
	.dialog_box {
		/deep/.el-dialog {
			width: 600px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.type_box {
				display: flex;

				// align-items: center;
				.lefts {
					width: 21px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.icons {
						width: 21px;
						height: 21px;
						background: #2373C8;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						text-align: center;
						line-height: 21px;
						border-radius: 50%;

					}

					.bordes {
						margin: auto;
						height: 87px;
						border-right: 3px dotted #2373C8;
					}

					.bordes2 {
						margin: auto;
						height: 137px;
						border-right: 3px dotted #2373C8;
					}
				}

				.lefts2 {
					width: 21px;
					display: flex;

					.icons {
						width: 21px;
						height: 21px;
						background: #2373C8;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						text-align: center;
						line-height: 21px;
						border-radius: 50%;

					}
				}

				.rights {
					padding-left: 14px;

					.rights_top {
						display: flex;

						.text_box {

							.text1 {
								font-weight: 400;
								font-size: 14px;
								color: #333333;
							}

							.text2 {
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								font-size: 13px;
								color: #888888;
								margin-top: 9px;

								span {
									margin-right: 2px;
									color: #FF0000;
								}
							}
						}

						.btn_box {
							margin-left: 54px;

							.btns {
								width: 91px;
								height: 31px;
								background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
								border-radius: 4px 4px 4px 4px;
								cursor: pointer;
								line-height: 31px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								font-size: 14px;
								color: #FFFFFF;
								text-align: center;

								a {
									color: #FFFFFF;
								}

								&:hover {

									background: linear-gradient(90deg, #036ad8 0%, #157DE9 100%);
								}
							}
						}
					}

					.rights_bom {
						display: flex;

						.up_files {
							margin-top: 24px;
							padding: 8px 10px;
							background: #F7F8FA;
							border-radius: 4px 4px 4px 4px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.span1 {
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								font-size: 14px;
								color: #CD962A;
							}
						}
					}

				}
			}
		}

	}
</style>