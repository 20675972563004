import Vue from "vue";

/**
   let vali_list = [
        {col: 'name', type: '', title: '真实姓名'},
        {col: 'card', type: '', title: '身份证号', reg:/\d+/}
    ]
    if(this.$formValidate(vali_list, this.form, alertErr)) {
        return 
    }
 */
// 未通过验证返回 true
// 通过验证返回 false


 
function formValidate(vali_list, form, alert) {
  let no_pass = false;
  no_pass = vali_list.some(function (item) {
    let { col, type, title, reg } = item;
    let typeMap = {
      '': '输入',
      'input': '输入',
      'date': '选择',
      'time': '选择',
      'select': '选择',
      'upload': '上传',
    };  
    type = typeMap[type] || '';
    type = type ? type : "输入"; //验证类型 输入 | 选择
  

    let ret = false; //本次验证结果
    let curr_val = form[col]; //验证值
    // debugger

    if ((reg && !reg.test(curr_val)) || !curr_val) {
      ret = true;
      alertErr(`请${type}${title}`);
    }

    return ret;
  });
  console.log("no_pass", no_pass);

  return no_pass;
}

Vue.prototype.$formValidate = formValidate;

export default {
  formValidate,
};
